<template>
    <div class="form_box">
        <el-form label-position="left" label-width="85px" size="small">
            <el-form-item label="产品" required>
                <div class="info">
                    {{ $tableDataHandle.productType(disabledForm.productType) }}
                </div>
            </el-form-item>
            <el-form-item label="企业名称" required>
                <div class="info">
                    {{
                        disabledForm.dealCustomerBO
                            ? disabledForm.dealCustomerBO.companyName || '- -'
                            : '- -'
                    }}
                </div>
            </el-form-item>
            <el-form-item label="商机类型" required>
                <el-select
                    v-model="form.businessType"
                    placeholder="请选择"
                    clearable
                    :disabled="disabled"
                    style="width:270px"
                >
                    <el-option
                        v-for="item in $searchForm.boType()"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="预估账户数" required>
                <el-input
                    v-model="form.estimateNumber"
                    placeholder="请输入"
                    :disabled="disabled"
                    required
                ></el-input>
            </el-form-item>
            <el-form-item label="预估年限" required>
                <el-input
                    v-model="form.estimateYear"
                    placeholder="请输入"
                    :disabled="disabled"
                    required
                ></el-input>
            </el-form-item>
            <el-form-item label="预估金额" required>
                <el-input
                    v-model="form.estimateAmount"
                    placeholder="请输入"
                    :disabled="disabled"
                    required
                ></el-input>
            </el-form-item>
            <el-form-item label="预计签约时间" required>
                <el-date-picker
                    v-model="form.estimateSignTime"
                    type="date"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择"
                    :disabled="disabled"
                    style="width:270px"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="邮箱运营">
                <div
                    class="mail_box"
                    v-for="(item, index) in dealCustomerReportOperateDOList"
                    :key="item.id"
                >
                    <el-select
                        v-model="item.adminId"
                        placeholder="输入员工名称搜索选择"
                        style="width:270px"
                        filterable
                        clearable
                        :disabled="disabled"
                    >
                        <el-option
                            v-for="itm in departmentRoleList"
                            :key="itm.id"
                            :label="itm.nickName"
                            :value="itm.id"
                        >
                        </el-option>
                    </el-select>
                    <el-button
                        v-if="index == 0 && !disabled"
                        type="primary"
                        @click="add"
                        class="el-icon-plus"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-if="index > 0 && !disabled"
                        class="el-icon-minus"
                        @click="del(index)"
                        type="primary"
                        circle
                        size="mini"
                    ></el-button>
                </div>
            </el-form-item>
            <el-form-item label="报备开始时间">
                <div class="info">
                    {{
                        disabledForm.reportTime
                            ? disabledForm.reportTime.split(' ')[0]
                            : '- -'
                    }}
                </div>
            </el-form-item>
            <el-form-item label="报备到期时间">
                <div class="info">
                    {{
                        disabledForm.reportExpireTime
                            ? disabledForm.reportExpireTime.split(' ')[0]
                            : '- -'
                    }}
                </div>
            </el-form-item>
            <el-form-item label="报备人">
                <div class="info">
                    {{ disabledForm.adminName || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="账户数">
                <div class="info">
                    {{
                        disabledForm.dealCustomerBO
                            ? disabledForm.dealCustomerBO.number || '- -'
                            : '- -'
                    }}
                </div>
            </el-form-item>
            <el-form-item label="产品开通时间">
                <div class="info">
                    {{
                        disabledForm.dealCustomerBO
                            ? disabledForm.dealCustomerBO.reportTime
                                ? disabledForm.dealCustomerBO.reportTime.split(
                                      ' '
                                  )[0]
                                : '- -'
                            : '- -'
                    }}
                </div>
            </el-form-item>
            <el-form-item label="产品到期时间">
                <div class="info">
                    {{
                        disabledForm.dealCustomerBO
                            ? disabledForm.dealCustomerBO.reportExpireTime
                                ? disabledForm.dealCustomerBO.reportExpireTime.split(
                                      ' '
                                  )[0]
                                : '- -'
                            : '- -'
                    }}
                </div>
            </el-form-item>
            <el-form-item label="商机进度">
                <el-select
                    v-model="form.businessProgress"
                    placeholder="请选择"
                    clearable
                    style="width:270px"
                    :disabled="disabled"
                >
                    <el-option
                        v-for="item in $searchForm.boSpeed()"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { roleList } from '@/api/customer/customer.js';
import { customerList } from '@/api/user/boreport.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            mailList: [{}],
            form: {},
            disabledForm: {},
            dealCustomerReportOperateDOList: [],
            departmentRoleList: [],
            disabled: false,
            customerFormList: [],
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row, disabled) {
            this.dealCustomerReportOperateDOList = [];
            this.disabled = disabled;
            this.form = {
                businessType: row.businessType,

                estimateNumber: row.estimateNumber,
                estimateYear: row.estimateYear,
                estimateAmount: row.estimateAmount,
                estimateSignTime: row.estimateSignTime,
                businessProgress: row.businessProgress,
            };
            this.disabledForm = {
                productType: row.productType,
                reportTime: row.reportTime,
                reportExpireTime: row.reportExpireTime,
                adminName: row.adminName,
                dealCustomerBO: {
                    companyName: row.dealCustomerBO.companyName,
                    number: row.dealCustomerBO.number,
                    reportTime: row.dealCustomerBO.reportTime,
                    reportExpireTime: row.dealCustomerBO.reportExpireTime,
                },
            };
            this.dealCustomerReportOperateDOList =
                row.dealCustomerReportOperateBOList.length > 0
                    ? row.dealCustomerReportOperateBOList
                    : [{}];

            this.roleList();
            // this.customerList(row.productType);
        },
        //   添加邮箱运营
        add() {
            this.dealCustomerReportOperateDOList.push({});
        },
        //     删除邮箱运营
        del(index) {
            this.dealCustomerReportOperateDOList.splice(index, 1);
        },
        // 角色列表
        roleList() {
            let data = {
                param: {
                    roleTypeList: [8, 17],
                },
            };
            roleList(data).then((res) => {
                if (res.code == 200) {
                    this.departmentRoleList = res.data;
                }
            });
        },
        // 已成交客户列表
        customerList(type) {
            let data = {
                param: {
                    type,
                },
                pageNum: 0,
                pageSize: 0,
            };
            customerList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.customerFormList = res.data.list;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.form_box {
    width: 100%;
    padding-top: 20px;
    .el-form {
        width: 90%;
        margin-left: 7%;
    }
}
.mail_box + .mail_box {
    margin-top: 10px;
}
.el-input {
    width: 270px;
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border: 2px solid #2370eb;
    margin-left: 10px;
}
.info {
    width: 270px;
    background-color: #f9f9f9;
    color: #333;
    box-sizing: border-box;
    padding: 0 15px;
    border-radius: 4px;
}
</style>
