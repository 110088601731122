<template>
    <div class="table">
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column
                label="企业名称"
                min-width="192"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div class="info" @click="onUpdate(scope.row)">
                        {{
                            scope.row.dealCustomerBO
                                ? scope.row.dealCustomerBO.companyName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="产品"
                :width="$tableDataHandle.getTextWidth('嗷嗷嗷嗷啊')"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            $tableDataHandle.productType(scope.row.productType)
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="报备有效期"
                min-width="115"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        <p>
                            <img src="@/assets/shi.png" alt="" />
                            {{
                                scope.row.reportTime
                                    ? scope.row.reportTime.split(' ')[0]
                                    : '- -'
                            }}
                        </p>
                        <p>
                            <img src="@/assets/mo.png" alt="" />
                            {{
                                scope.row.reportExpireTime
                                    ? scope.row.reportExpireTime.split(' ')[0]
                                    : '- -'
                            }}
                        </p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="报备人" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.adminName ? scope.row.adminName : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="商机类型"
                :width="$tableDataHandle.getTextWidth('嗷嗷嗷2')"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ $tableDataHandle.boType(scope.row.businessType) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="预估账户数" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.estimateNumber
                                ? scope.row.estimateNumber
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="预估年限" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.estimateYear
                                ? scope.row.estimateYear
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="预估金额" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.estimateAmount
                                ? scope.row.estimateAmount
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="邮箱运营">
                <template slot-scope="scope">
                    <div
                        v-if="
                            scope.row.dealCustomerReportOperateBOList &&
                                scope.row.dealCustomerReportOperateBOList
                                    .length > 0
                        "
                    >
                        <el-tooltip
                            class="item"
                            effect="dark"
                            placement="bottom"
                        >
                            <div slot="content">
                                <div class="tool_content">
                                    <div
                                        v-for="(item, index) in scope.row
                                            .dealCustomerReportOperateBOList"
                                        :key="item.adminId"
                                    >
                                        <span>邮箱运营{{ index + 1 }}：</span>
                                        {{ item.adminName }}
                                    </div>
                                </div>
                            </div>
                            <p class="tool">
                                <span>{{
                                    scope.row.dealCustomerReportOperateBOList[0]
                                        .adminName
                                }}</span>
                                <em
                                    v-if="
                                        scope.row
                                            .dealCustomerReportOperateBOList
                                            .length > 1
                                    "
                                    >{{
                                        scope.row
                                            .dealCustomerReportOperateBOList
                                            .length
                                    }}</em
                                >
                            </p>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="预计签约时间"
                :width="$tableDataHandle.getTextWidth('2022-12-011')"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.estimateSignTime
                                ? scope.row.estimateSignTime.split(' ')[0]
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="商机进度"
                :width="$tableDataHandle.getTextWidth('嗷嗷嗷2')"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            $tableDataHandle.boStatus(
                                scope.row.businessProgress
                            )
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="往来"
                :width="$tableDataHandle.getTextWidth('2022-12-011')"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div
                        v-if="
                            scope.row.dealCustomerIntercourseBOList &&
                                scope.row.dealCustomerIntercourseBOList.length >
                                    0
                        "
                    >
                        <el-tooltip
                            class="item"
                            effect="dark"
                            placement="bottom"
                        >
                            <div slot="content">
                                <div class="deal_content">
                                    <span>往来内容：</span>
                                    {{
                                        scope.row
                                            .dealCustomerIntercourseBOList[0]
                                            .intercourseContent
                                    }}
                                </div>
                            </div>
                            <p class="tool">
                                <span>{{
                                    scope.row.dealCustomerIntercourseBOList[0]
                                        .createTime
                                        ? scope.row.dealCustomerIntercourseBOList[0].createTime.split(
                                              ' '
                                          )[0] || '- -'
                                        : '- -'
                                }}</span>
                            </p>
                        </el-tooltip>
                    </div>
                    <div v-else>
                        - -
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="账户数" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.DealCustomerBO
                                ? scope.row.DealCustomerBO.number || '- -'
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="产品有效期"
                min-width="115"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        <p>
                            <img src="@/assets/shi.png" alt="" />
                            {{
                                scope.row.DealCustomerBO
                                    ? scope.row.DealCustomerBO.reportTime ||
                                      '- -'
                                    : '- -'
                            }}
                        </p>
                        <p>
                            <img src="@/assets/mo.png" alt="" />
                            {{
                                scope.row.DealCustomerBO
                                    ? scope.row.DealCustomerBO
                                          .reportExpireTime || '- -'
                                    : '- -'
                            }}
                        </p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" min-width="50">
                <template slot-scope="scope">
                    <el-button
                        style="color:#2370EB"
                        type="text"
                        @click="onGiveUp(scope.row)"
                        >放弃</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display:block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
import { dealReportList } from '@/api/user/boreport.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
        };
    },
    components: {},
    created() {},
    methods: {
        getData(searchVal, pageNum, pageSize, btnP) {
            let data = {
                param: {},
                pageNum: pageNum,
                pageSize: pageSize,
            };
            if (searchVal.productType == '腾讯邮箱') {
                data.param.productType = 1;
            }
            if (searchVal.productType == '阿里邮箱') {
                data.param.productType = 2;
            }
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            if (btnP.customer) {
                if (searchVal.partakeAdminId == '我参与的') {
                    data.param.partakeAdminId = sessionStorage.getItem(
                        'adminId'
                    );
                }
            } else {
                data.param.partakeAdminId = sessionStorage.getItem('adminId');
            }

            if (btnP.department) {
                if (searchVal.departmentId) {
                    data.param.departmentId = searchVal.departmentId;
                }
            } else {
                data.param.departmentId = sessionStorage.getItem(
                    'departmentId'
                );
            }
            if (btnP.reportUser) {
                if (searchVal.adminId) {
                    data.param.adminId = searchVal.adminId;
                }
            } else {
                data.param.adminId = sessionStorage.getItem('adminId');
            }

            if (searchVal.operateAdminId) {
                data.param.operateAdminId = searchVal.operateAdminId;
            }
            if (searchVal.businessType) {
                data.param.businessType = searchVal.businessType;
            }
            if (searchVal.businessProgress) {
                data.param.businessProgress = searchVal.businessProgress;
            } else {
                data.param.businessProgressList = [1, 2, 3];
            }
            dealReportList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('totalNum', res.data.total);
                }
            });
        },
        // 放弃
        onGiveUp(data) {
            this.$emit('onGiveUp', data);
        },
        // 编辑
        onUpdate(data) {
            this.$emit('onUpdate', data);
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table {
    width: 100%;
    height: 100%;
    .tool {
        padding-right: 20px;
        position: relative;
        span {
            overflow: hidden;
            display: block;
        }
        em {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
            height: 14px;
            display: inline-block;
            border-radius: 50%;
            font-size: 12px;
            font-style: normal;
            border: 1px solid #2370eb;
            color: #2370eb;
            line-height: 14px;
            text-align: center;
        }
    }
    .info {
        cursor: pointer;
        color: #2370eb;
    }
}
.tool_content {
    div + div {
        margin-top: 5px;
    }
}
/deep/ .el-table td {
    padding: 0;
    height: 58px;
}
.deal_content {
    display: flex;
    span {
        display: block;
    }
}
</style>
