import http from '@/utils/http.js'

// 列表
export function dealReportList(data) {
     return http({
          url: "/jasoboss/customer/deal/report/list",
          method: "post",
          data
     })
}
// 添加
export function addDealReport(data) {
     return http({
          url: "/jasoboss/customer/deal/report/add",
          method: "post",
          data
     })
}
// 修改
export function updateDealReport(data) {
     return http({
          url: "/jasoboss/customer/deal/report/update",
          method: "post",
          data
     })
}
// 获取单个报备
export function getOneDealReport(data) {
     return http({
          url: "/jasoboss/customer/deal/report/get",
          method: "post",
          data
     })
}
// 已成交客户列表
export function customerList(data) {
     return http({
          url: "/jasoboss/customer/deal/list",
          method: "post",
          data
     })
}
// 往来列表
export function dealIntercourseList(data) {
     return http({
          url: "/jasoboss/customer/deal/intercourse/list",
          method: "post",
          data
     })
}
// 往来添加
export function addDealIntercourse(data) {
     return http({
          url: "/jasoboss/customer/deal/intercourse/add",
          method: "post",
          data
     })
}
// 往来添加说明
export function addDealIntercourseDescribe(data) {
     return http({
          url: "/jasoboss/customer/deal/intercourse/describe/add",
          method: "post",
          data
     })
}
// 往来说明删除
export function delDealIntercourseDescribe(data) {
     return http({
          url: "/jasoboss/customer/deal/intercourse/describe/delete",
          method: "post",
          data
     })
}
// 报备统计
export function boReportBrief(data) {
     return http({
          url: "/jasoboss/customer/deal/report/get/brief",
          method: "post",
          data
     })
}