<template>
    <div class="dealings_box">
        <div
            class="form_box"
            v-for="(item, index) in dealingsForm"
            :key="index"
        >
            <el-form label-position="left" label-width="67px" size="small">
                <div class="btn_box">
                    <el-button class="cancel" @click="cancel(index)"
                        >取消</el-button
                    >
                    <el-button class="keep" @click="submit(index)"
                        >保存</el-button
                    >
                </div>
                <div class="flex_box">
                    <el-form-item label="往来标题" style="flex:2">
                        <el-input
                            v-model="item.intercourseTitle"
                            style="width:170px;"
                            placeholder="请输入"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="跟进人" style="flex:1.2">
                        <el-input
                            v-model="item.name"
                            placeholder="请输入"
                            disabled
                        ></el-input>
                    </el-form-item>
                </div>
                <div class="flex_box">
                    <el-form-item label="往来时间" style="flex:2">
                        <el-date-picker
                            v-model="item.intercourseTime"
                            type="datetime"
                            placeholder="选择日期时间"
                            size="small"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            style="width:170px;"
                            :picker-options="expireTimeOption"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="联系方式" style="flex:1.2" required>
                        <el-select
                            v-model="item.contactWay"
                            placeholder="请选择"
                            clearable
                        >
                            <el-option label="电话" :value="1"></el-option>
                            <el-option label="微信" :value="2"></el-option>
                            <el-option label="邮箱" :value="3"></el-option>
                            <el-option label="QQ" :value="4"></el-option>
                            <el-option label="短信" :value="5"></el-option>
                            <el-option label="拜访" :value="6"></el-option>
                            <el-option label="官网表单" :value="8"></el-option>
                            <el-option label="座机" :value="9"></el-option>
                            <el-option label="其他" :value="7"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-form-item label="往来内容" required>
                    <el-input
                        type="textarea"
                        v-model="item.intercourseContent"
                        placeholder="请输入"
                        style="margin-left:0"
                        rows="3"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div
            class="dealings_list"
            style="border-bottom:none"
            v-if="intercourseList.length > 0"
        >
            <div
                class="form_box"
                v-for="item in intercourseList"
                :key="item.id"
            >
                <el-form label-position="left" label-width="67px" size="small">
                    <div class="flex_box">
                        <el-form-item label="往来标题" style="flex:2">
                            <div class="info">{{ item.intercourseTitle }}</div>
                        </el-form-item>
                        <el-form-item label="跟进人" style="flex:1.2">
                            <div>{{ item.adminName }}</div>
                        </el-form-item>
                    </div>
                    <div class="flex_box">
                        <el-form-item label="往来时间" style="flex:2">
                            <div class="info">{{ item.intercourseTime }}</div>
                        </el-form-item>
                        <el-form-item label="联系方式" style="flex:1.2">
                            <div>{{ $tableDataHandle.contactWay(item) }}</div>
                        </el-form-item>
                    </div>
                    <el-form-item label="往来内容">
                        <div style="color:#FA6400">
                            {{ item.intercourseContent }}
                        </div>
                    </el-form-item>
                    <div class="comment_content_box">
                        <el-steps direction="vertical">
                            <el-step
                                v-for="itm in item.describeList"
                                :key="itm.id"
                            >
                                <template slot="title">
                                    <span style="color:#2370EB">{{
                                        itm.adminName
                                    }}</span>
                                    {{ itm.createTime }}
                                </template>
                                <template slot="icon">
                                    <i class="el-icon-user-solid"></i>
                                </template>
                                <template
                                    slot="description"
                                    style="white-space：pre-line"
                                >
                                    <span
                                        v-html="enter(itm.describeContent)"
                                    ></span>
                                    <i
                                        :class="
                                            itm.adminId == adminId
                                                ? 'el-icon-delete red'
                                                : 'el-icon-delete hui'
                                        "
                                        @click="delComment(itm)"
                                    ></i>
                                </template>
                            </el-step>
                        </el-steps>
                    </div>
                    <div class="comment">
                        <el-input
                            type="textarea"
                            style="margin-bottom:13px"
                            v-model="item.describeContent"
                            placeholder="请输入评论内容"
                            rows="3"
                        >
                        </el-input>
                        <el-button size="mini" @click="submitComment(item)"
                            >发送评论</el-button
                        >
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import {
    dealIntercourseList,
    addDealIntercourse,
    addDealIntercourseDescribe,
    delDealIntercourseDescribe,
} from '@/api/user/boreport.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            dealingsForm: [],
            intercourseList: [],
            data: {},
            disabled: false,
            nickName: sessionStorage.getItem('nickName'),
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() >= Date.now();
                },
            },
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row, disabled) {
            this.disabled = disabled;
            this.data = row;
            this.dealIntercourseList();
        },
        addDealingsForm() {
            if (this.disabled) {
                return;
            }
            this.dealingsForm.push({
                name: this.nickName,
            });
        },
        // 往来列表
        dealIntercourseList() {
            let data = {
                param: {
                    dealCustomerId: this.data.dealCustomerId,
                    dealCustomerReportId: this.data.id,
                },
            };
            dealIntercourseList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.intercourseList = res.data.list;
                }
            });
        },
        enter(str) {
            let str1 = str.replace(/\n/g, '<br/>');
            return str1;
        },
        //     取消提交往来
        cancel(index) {
            this.dealingsForm.splice(index, 1);
        },
        //     提交往来
        submit(index) {
            // console.log(this.dealingsForm[index]);
            let data = {
                param: {
                    contactWay: this.dealingsForm[index].contactWay,
                    intercourseContent: this.dealingsForm[index]
                        .intercourseContent,
                    intercourseTime: this.dealingsForm[index].intercourseTime,
                    intercourseTitle: this.dealingsForm[index].intercourseTitle,
                    dealCustomerId: this.data.dealCustomerId,
                    dealCustomerReportId: this.data.id,
                },
            };
            if (!this.dealingsForm[index].contactWay) {
                return this.$message.error('请选择联系方式');
            }
            if (!this.dealingsForm[index].intercourseContent) {
                return this.$message.error('请输入往来内容');
            }
            addDealIntercourse(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.dealingsForm = [];
                    this.$message.success('操作成功');
                    this.dealIntercourseList();
                }
            });
        },
        //     提交评论
        submitComment(row) {
            let data = {
                param: {
                    intercourseId: row.id,
                    describeContent: row.describeContent,
                },
            };
            addDealIntercourseDescribe(data).then((res) => {
                if (res.code == 200) {
                    this.dealingsForm = [];
                    this.$message.success('操作成功');
                    this.dealIntercourseList();
                }
            });
        },
        //     删除评论
        delComment(itm) {
            let data = {
                param: {
                    id: itm.id,
                },
            };
            delDealIntercourseDescribe(data).then((res) => {
                if (res.code == 200) {
                    this.dealingsForm = [];
                    this.$message.success('操作成功');
                    this.dealIntercourseList();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.dealings_box {
    width: 100%;
    overflow-y: auto;
    height: calc(100% - 55px);
    .form_box,
    .dealings_list {
        border-bottom: 1px dashed #e5e5e5;
        margin-top: 15px;
        > .el-form {
            width: 90%;
            margin: 0 auto;
            .comment {
                text-align: center;
                padding-bottom: 20px;
                button {
                    color: rgb(255, 255, 255);
                    background: rgb(35, 112, 235);
                    border-color: rgb(35, 112, 235);
                    padding: 5px 20px;
                }
            }
            .comment_content_box {
            }
            .flex_box {
                display: flex;
                .info {
                    width: 170px;
                }
            }
            .btn_box {
                text-align: right;
                margin-bottom: 10px;
                .cancel {
                    color: rgb(35, 112, 235);
                    background: rgb(255, 255, 255);
                    border-color: rgb(35, 112, 235);
                    padding: 5px;
                }
                .keep {
                    color: rgb(255, 255, 255);
                    background: rgb(35, 112, 235);
                    border-color: rgb(35, 112, 235);
                    padding: 5px;
                }
            }
        }
    }
}
.el-form-item {
    margin-bottom: 13px;
}
/deep/ .el-form-item__label {
    padding: 0 8px 0 0;
}
/deep/ .el-input--small .el-input__icon {
    display: none !important;
}
/deep/ .el-input__inner {
    padding: 0;
    padding-left: 10px;
}
/deep/ .el-step__icon {
    width: 20px;
    height: 20px;
    background-color: #999999;
    border-color: transparent;
    color: #fff;
    transform: scale(0.7);
}
.amlm .is-vertical {
    flex-direction: row;
}
/deep/ .el-step__main {
    margin-bottom: 20px;
}
/deep/ .el-step__title {
    font-size: 13px;
    color: #666666;
}
/deep/ .el-step.is-vertical .el-step__head {
    width: 20px;
}
/deep/ .el-step.is-vertical .el-step__line {
    width: 0px;
    top: 10px;
    bottom: -10px;
    left: 9px;
    border-left: 2px dashed #e5e5e5;
}
/deep/ .el-step__line {
    background-color: transparent;
}
/deep/ .el-step__description.is-wait {
    color: #333 !important;
    position: relative;
    padding-right: 10%;
    .red {
        color: #da5120;
        font-size: 14px;
        position: absolute;
        right: 0;
        top: 4px;
        cursor: pointer;
    }
    .hui {
        color: #666;
    }
}
</style>
