<template>
    <div class="detail">
        <div class="detail_box">
            <div style="overflow-y:auto">
                <Form ref="form"></Form>
            </div>
            <div>
                <div class="dealings_title">
                    往来记录
                    <el-button size="mini" @click="addDealingsForm"
                        >新增往来
                    </el-button>
                </div>
                <Dealings ref="dealings"></Dealings>
            </div>
        </div>
        <div class="dialog_footer">
            <el-button round size="mini" @click="onClose">取 消</el-button>
            <el-button
                type="primary"
                round
                size="mini"
                style="    background: rgb(35, 112, 235);
    border-color: rgb(35, 112, 235);
    color: rgb(255, 255, 255);"
                @click="onSubmit"
                >确 定</el-button
            >
        </div>
    </div>
</template>

<script>
import { updateDealReport, getOneDealReport } from '@/api/user/boreport.js';
import Form from './components/form.vue';
import Dealings from './components/dealings/dealings.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            data: {},
            disabled: false,
        };
    },
    components: {
        Form,
        Dealings,
    },
    created() {},
    methods: {
        getData(row, disabled) {
            this.data = row;
            this.disabled = disabled;
            let data = {
                param: {
                    id: row.id,
                },
            };
            getOneDealReport(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$refs.form.getData(res.data, disabled);
                    this.$refs.dealings.getData(res.data, disabled);
                }
            });
        },
        addDealingsForm() {
            this.$refs.dealings.addDealingsForm();
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.data.id,
                    ...this.$refs.form.$data.form,
                },
            };
            if (!this.$refs.form.$data.form.businessType) {
                return this.$message.error('请选择商机类型');
            }
            if (!this.$refs.form.$data.form.estimateNumber) {
                return this.$message.error('请输入预估账户数');
            }
            if (!this.$refs.form.$data.form.estimateYear) {
                return this.$message.error('请输入预估年限');
            }
            if (!this.$refs.form.$data.form.estimateAmount) {
                return this.$message.error('请输入预估金额');
            }
            if (!this.$refs.form.$data.form.estimateSignTime) {
                return this.$message.error('请选择预计签约时间');
            }
            data.param.dealCustomerReportOperateDOList = [];
            for (let i in this.$refs.form.$data
                .dealCustomerReportOperateDOList) {
                if (
                    this.$refs.form.$data.dealCustomerReportOperateDOList[i]
                        .adminId
                ) {
                    data.param.dealCustomerReportOperateDOList.push({
                        adminId: this.$refs.form.$data
                            .dealCustomerReportOperateDOList[i].adminId,
                    });
                }
            }
            let arr = data.param.dealCustomerReportOperateDOList;
            // 判断id是否重复
            // 返回一个id的数组
            const newArr = arr.map((item) => item.adminId);
            const arrSet = new Set(newArr);
            if (arrSet.size !== newArr.length) {
                return this.$message.error('邮箱运营不能重复');
            }
            updateDealReport(data).then((res) => {
                if (res.code == 200 && res.data) {
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.detail {
    width: 100%;
    height: 100%;
    .detail_box {
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        background-color: #f0f2f4;
        justify-content: space-between;
        > div {
            width: 49.5%;
            background-color: #fff;
            overflow: hidden;
            .dealings_title {
                width: 100%;
                height: 55px;
                box-sizing: border-box;
                border-bottom: 1px solid #e5e5e5;
                font-size: 13px;
                position: relative;
                padding-left: 22px;
                line-height: 55px;
                button {
                    background-color: transparent;
                    border-radius: 2px;
                    border: 1px solid #2370eb;
                    color: #2370eb;
                    padding: 4px 15px;
                    font-size: 12px;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .dealings_title:before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                height: 16px;
                width: 2px;

                background-color: #2370eb;
                transform: translateY(-43%);
            }
        }
    }
    .dialog_footer {
        height: 50px;
        box-sizing: border-box;
        width: 100%;
        line-height: 45px;
        text-align: right;
        padding-right: 10px;
        box-sizing: border-box;
        border-top: 1px solid #e5e5e5;
    }
}
</style>
